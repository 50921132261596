import {
  GET_INVOICE,
  GET_INVOICE_SUCCESS,
  GET_INVOICE_FAILED,
  GET_INVOICE_DETAILS,
  GET_INVOICE_DETAILS_SUCCESS,
  GET_INVOICE_DETAILS_FAILED,
  UPDATE_INVOICE,
  UPDATE_INVOICE_SUCCESS,
  UPDATE_INVOICE_FAILED,
  UPDATE_INVOICE_RESET,
  UPDATE_INVOICE_LIST,
  DELETE_INVOICE,
  DELETE_INVOICE_SUCCESS,
  DELETE_INVOICE_FAILED,
  DELETE_INVOICE_RESET,
  GENERATE_INVOICE_FAILED,
  GENERATE_INVOICE_RESET,
  GENERATE_INVOICE_SUCCESS,
  GENERATE_INVOICE,
  GET_INVOICE_PDF,
  GET_INVOICE_PDF_DONE,
  GET_INVOICE_PDF_ERROR,
  SET_INVOICE_STARTDATE_FILTER,
  DOWNLOAD_ALL_INVOICES,
  DOWNLOAD_ALL_INVOICES_DONE,
  DOWNLOAD_ALL_INVOICES_ERROR,
} from './types';
import {
  currentMonthFirstDay,
  currentMonthLastDay,
} from '../../../utils/common';

const initialState = {
  startDateFilter: {
    start: currentMonthFirstDay,
    end: currentMonthLastDay,
  },
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
  retrieve: {
    isLoading: false,
    data: null,
    error: null,
  },
  save: {
    isLoading: false,
    isUpdated: false,
    error: null,
    data: null,
  },
  remove: {
    isLoading: false,
    isDeleted: false,
    error: null,
  },
  generate: {
    isLoading: false,
    isCreated: false,
    error: null,
    data: null,
  },
  getPDF: {
    invoiceId: null,
    isLoading: false,
    error: null,
  },
  downloadInvoices: {
    isLoading: false,
    error: null,
  },
};
let invoiceData = [];

const manage = (state = initialState, action) => {
  switch (action.type) {
    case SET_INVOICE_STARTDATE_FILTER:
      return {
        ...state,
        startDateFilter: action.payload,
      };
    case DOWNLOAD_ALL_INVOICES:
      return {
        ...state,
        downloadInvoices: {
          ...state.downloadInvoices,
          isLoading: true,
          error: null,
        },
      };
    case DOWNLOAD_ALL_INVOICES_DONE:
      return {
        ...state,
        downloadInvoices: {
          ...state.downloadInvoices,
          isLoading: false,
        },
      };
    case DOWNLOAD_ALL_INVOICES_ERROR:
      return {
        ...state,
        downloadInvoices: {
          ...state.downloadInvoices,
          isLoading: false,
          error: action.payload,
        },
      };
    case GET_INVOICE_PDF_ERROR:
      return {
        ...state,
        getPDF: {
          ...state.getPDF,
          isLoading: false,
          error: action.payload,
        },
      };
    case GET_INVOICE_PDF:
      return {
        ...state,
        getPDF: {
          ...state.getPDF,
          isLoading: true,
          invoiceId: action.payload,
          error: null,
        },
      };
    case GET_INVOICE_PDF_DONE:
      return {
        ...state,
        getPDF: {
          ...state.getPDF,
          isLoading: false,
          invoiceId: null,
        },
      };
    case GENERATE_INVOICE_RESET:
      return {
        ...state,
        generate: initialState.generate,
      };
    case GENERATE_INVOICE:
      return {
        ...state,
        generate: {
          ...state.generate,
          isLoading: true,
          isCreated: false,
          error: null,
        },
      };

    case GENERATE_INVOICE_SUCCESS:
      return {
        ...state,
        generate: {
          ...state.generate,
          isLoading: false,
          isCreated: true,
          data: action.payload,
        },
      };

    case GENERATE_INVOICE_FAILED:
      return {
        ...state,
        generate: {
          ...state.generate,
          isLoading: false,
          error: action.payload,
        },
      };

    case GET_INVOICE:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
        },
      };

    case GET_INVOICE_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_INVOICE_FAILED:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    case GET_INVOICE_DETAILS:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: true,
          data: null,
          error: null,
        },
      };
    case GET_INVOICE_DETAILS_SUCCESS:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_INVOICE_DETAILS_FAILED:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: false,
          error: action.payload,
        },
      };
    case DELETE_INVOICE_RESET:
      return {
        ...state,
        remove: initialState.remove,
      };
    case DELETE_INVOICE:
      return {
        ...state,
        remove: {
          ...state.remove,
          isLoading: true,
          isDeleted: false,
          error: null,
        },
      };
    case DELETE_INVOICE_SUCCESS:
      invoiceData = state.get.data.filter((n) => n.id !== action.payload);
      return {
        ...state,
        get: {
          ...state.get,
          data: invoiceData,
        },
        remove: {
          ...state.remove,
          isLoading: false,
          isDeleted: true,
        },
      };
    case DELETE_INVOICE_FAILED:
      return {
        ...state,
        remove: {
          ...state.remove,
          isLoading: false,
          error: action.payload,
        },
      };
    case UPDATE_INVOICE_LIST:
      return {
        ...state,
        get: {
          ...state.get,
          data: invoiceData,
        },
      };
    case UPDATE_INVOICE:
      return {
        ...state,
        save: {
          ...state.save,
          isLoading: true,
          isUpdated: false,
          data: null,
          error: null,
        },
      };
    case UPDATE_INVOICE_RESET:
      return {
        ...state,
        save: initialState.save,
      };
    case UPDATE_INVOICE_SUCCESS:
      invoiceData = state.get.data.map((n) => {
        let invoice = n;
        if (invoice.id === action.payload.id) {
          invoice = action.payload;
        }
        return invoice;
      });
      return {
        ...state,
        get: {
          ...state.get,
          data: invoiceData,
        },
        save: {
          ...state.save,
          isLoading: false,
          isUpdated: true,
          data: action.payload,
        },
      };
    case UPDATE_INVOICE_FAILED:
      return {
        ...state,
        save: {
          ...state.save,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default manage;
