export const GET_AGREEMENTS = 'GET_AGREEMENTS';
export const GET_AGREEMENTS_SUCCESS = 'GET_AGREEMENTS_SUCCESS';
export const GET_AGREEMENTS_FAILED = 'GET_AGREEMENTS_FAILED';
export const GET_AGREEMENT_DETAILS = 'GET_AGREEMENT_DETAILS';
export const GET_AGREEMENT_DETAILS_SUCCESS = 'GET_AGREEMENT_DETAILS_SUCCESS';
export const GET_AGREEMENT_DETAILS_FAILED = 'GET_AGREEMENT_DETAILS_FAILED';
export const CREATE_AGREEMENT = 'CREATE_AGREEMENT';
export const CREATE_AGREEMENT_SUCCESS = 'CREATE_AGREEMENT_SUCCESS';
export const CREATE_AGREEMENT_FAILED = 'CREATE_AGREEMENT_FAILED';
export const CREATE_AGREEMENT_RESET = 'CREATE_AGREEMENT_RESET';
export const UPDATE_AGREEMENT = 'UPDATE_AGREEMENT';
export const UPDATE_AGREEMENT_SUCCESS = 'UPDATE_AGREEMENT_SUCCESS';
export const UPDATE_AGREEMENT_FAILED = 'UPDATE_AGREEMENT_FAILED';
export const UPDATE_AGREEMENT_RESET = 'UPDATE_AGREEMENT_RESET';
export const DELETE_AGREEMENT = 'DELETE_AGREEMENT';
export const DELETE_AGREEMENT_SUCCESS = 'DELETE_AGREEMENT_SUCCESS';
export const DELETE_AGREEMENT_FAILED = 'DELETE_AGREEMENT_FAILED';
export const DELETE_AGREEMENT_RESET = 'DELETE_AGREEMENT_RESET';
export const BULK_EXPORT_AGREEMENT = 'BULK_EXPORT_AGREEMENT';
export const BULK_EXPORT_AGREEMENT_SUCCESS = 'BULK_EXPORT_AGREEMENT_SUCCESS';
export const BULK_EXPORT_AGREEMENT_FAILED = 'BULK_EXPORT_AGREEMENT_FAILED';
export const BULK_EXPORT_AGREEMENT_RESET = 'BULK_EXPORT_AGREEMENT_RESET';
