import {
  LOGIN_FAILED,
  LOGIN_REQUEST,
  LOGIN_RESET,
  LOGIN_SUCCESS,
} from "./types";

const initialState = {
 isAuthenticated: false,
  isLoading: false,
  isError: false,
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_RESET:
      return initialState;
    case LOGIN_REQUEST:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: true,
        isError: false,
      };
    case LOGIN_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
        isLoading: false,
      };

    case LOGIN_FAILED:
      return {
        ...state,
        isAuthenticated: false,
        isLoading: false,
        isError: action.payload,
      };
    default:
      return state;
  }
};
export default auth;
