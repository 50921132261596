import { eraseCookie } from './cookies';

export const tokenKey = 'token';
export const ADMINROLE = 'admin';
export const MEMBERROLE = 'member';
export const roleAccessLevel = { [ADMINROLE]: 1, [MEMBERROLE]: 2 };
export const maxAccessLevel = Object.keys(roleAccessLevel).reduce((a, b) =>
  roleAccessLevel[a] > roleAccessLevel[b]
    ? roleAccessLevel[a]
    : roleAccessLevel[b]
);
export const isAdmin = (profile) => {
  return profile.role === ADMINROLE;
};
// Date Format
// export var dateFormat = 'YYYY-MM-DD';
// export var datePickerFormat = 'yyyy-MM-dd';

export var dateFormat = 'DD-MM-YYYY'; // for backend
export var dateFilterFormat = 'YYYY-MM-DD'; //for backend
export var datePickerFormat = 'dd-MM-yyyy';

export function dateStringToObject(dateString) {
  //date format dd-MM-yyyy
  var dateParts = dateString.split('-');
  // month is 0-based, that's why we need dataParts[1] - 1
  var dateObject = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0]);
  return dateObject;
}
var date = new Date();
export var currentMonthFirstDay = new Date(
  date.getFullYear(),
  date.getMonth(),
  1
);
export var currentMonthLastDay = new Date(
  date.getFullYear(),
  date.getMonth() + 1,
  0
);

export const qargsToQstring = (query_args = {}) => {
  let query_string = '';
  let idx = 0;
  for (var key in query_args) {
    if (idx === 0) {
      query_string += '?' + key + '=' + query_args[key];
    } else {
      query_string += '&' + key + '=' + query_args[key];
    }
    idx++;
  }
  return query_string;
};

export const formatAxiosErrorMessage = (error) => {
  let err_msg = '';
  try {
    if (error.response) {
      if (
        401 === error.response.status &&
        window.location.pathname !== '/login'
      ) {
        eraseCookie(tokenKey);
        window.location.replace('/login');
      }
      /*
       * The request was made and the server responded with a
       * status code that falls out of the range of 2xx
       */
      if ('detail' in error.response.data) {
        err_msg = error.response.data.detail;
      } else {
        for (var key in error.response.data) {
          err_msg += error.response.data[key];
        }
      }
    } else if (error.request) {
      /*
       * The request was made but no response was received, `error.request`
       */
      err_msg = 'Something went wrong';
    } else {
      // Something happened in setting up the request and triggered an Error
      err_msg = error.message;
    }
  } catch (err) {
    err_msg = 'Something went wrong';
  }
  return err_msg;
};

export const cleanObject = function (object) {
  Object.entries(object).forEach(([k, v]) => {
    if (v && typeof v === 'object') cleanObject(v);
    if (
      (v && typeof v === 'object' && !Object.keys(v).length) ||
      v === null ||
      v === undefined ||
      v.length === 0
    ) {
      if (Array.isArray(object)) object.splice(k, 1);
      else if (!(v instanceof Date)) delete object[k];
    }
  });
  return object;
};

export const debounce = (func, delay) => {
  let debounceTimer;
  return function () {
    const context = this;
    const args = arguments;
    clearTimeout(debounceTimer);
    debounceTimer = setTimeout(() => func.apply(context, args), delay);
  };
};

export function dotStringToObject(obj) {
  const result = {};

  // For each object path (property key) in the object
  for (const objectPath in obj) {
    // Split path into component parts
    const parts = objectPath.split('.');

    // Create sub-objects along path as needed
    let target = result;
    while (parts.length > 1) {
      const part = parts.shift();
      target = target[part] = target[part] || {};
    }

    // Set value at end of path
    target[parts[0]] = obj[objectPath];
  }

  return result;
}

export function isEmpty(value) {
  return (
    value === undefined ||
    value === null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  );
}
