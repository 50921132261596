export const GET_MEDIA_PLAN = 'GET_MEDIA_PLAN';
export const GET_MEDIA_PLAN_SUCCESS = 'GET_MEDIA_PLAN_SUCCESS';
export const GET_MEDIA_PLAN_FAILED = 'GET_MEDIA_PLAN_FAILED';

export const CREATE_MEDIA_PLAN = 'CREATE_MEDIA_PLAN';
export const CREATE_MEDIA_PLAN_SUCCESS = 'CREATE_MEDIA_PLAN_SUCCESS';
export const CREATE_MEDIA_PLAN_FAILED = 'CREATE_MEDIA_PLAN_FAILED';
export const CREATE_MEDIA_PLAN_RESET = 'CREATE_MEDIA_PLAN_RESET';

export const UPDATE_MEDIA_PLAN = 'UPDATE_MEDIA_PLAN';
export const UPDATE_MEDIA_PLAN_SUCCESS = 'UPDATE_MEDIA_PLAN_SUCCESS';
export const UPDATE_MEDIA_PLAN_FAILED = 'UPDATE_MEDIA_PLAN_FAILED';
export const UPDATE_MEDIA_PLAN_RESET = 'UPDATE_MEDIA_PLAN_RESET';
export const UPDATE_MEDIA_PLAN_LIST = 'UPDATE_MEDIA_PLAN_LIST';

export const GET_MEDIA_PLAN_DETAILS = 'GET_MEDIA_PLAN_DETAILS';
export const GET_MEDIA_PLAN_DETAILS_SUCCESS = 'GET_MEDIA_PLAN_DETAILS_SUCCESS';
export const GET_MEDIA_PLAN_DETAILS_FAILED = 'GET_MEDIA_PLAN_DETAILS_FAILED';

export const DELETE_MEDIA_PLAN = 'DELETE_MEDIA_PLAN';
export const DELETE_MEDIA_PLAN_SUCCESS = 'DELETE_MEDIA_PLAN_SUCCESS';
export const DELETE_MEDIA_PLAN_FAILED = 'DELETE_MEDIA_PLAN_FAILED';
export const DELETE_MEDIA_PLAN_RESET = 'DELETE_MEDIA_PLAN_RESET';

export const SET_MEDIA_PLAN_STARTDATE_FILTER =
  'SET_MEDIA_PLAN_STARTDATE_FILTER';
