import {
  BULK_IMPORT_MEDIA_PLAN,
  BULK_IMPORT_MEDIA_PLAN_FAILED,
  BULK_IMPORT_MEDIA_PLAN_RESET,
  BULK_IMPORT_MEDIA_PLAN_SUCCESS,
  BULK_EXPORT_MEDIA_PLAN,
  BULK_EXPORT_MEDIA_PLAN_FAILED,
  BULK_EXPORT_MEDIA_PLAN_RESET,
  BULK_EXPORT_MEDIA_PLAN_SUCCESS,
} from './types';

const initialState = {
  bulkimport: {
    isLoading: false,
    isCreated: false,
    result: null,
    error: null,
  },
  bulkexport: {
    isLoading: false,
    error: null,
  },
};

const bulk = (state = initialState, action) => {
  switch (action.type) {
    case BULK_IMPORT_MEDIA_PLAN:
      return {
        ...state,
        bulkimport: {
          ...state.bulkimport,
          isLoading: true,
          isCreated: false,
          error: null,
          result: null,
        },
      };
    case BULK_IMPORT_MEDIA_PLAN_SUCCESS:
      return {
        ...state,
        bulkimport: {
          ...state.bulkimport,
          isLoading: false,
          isCreated: true,
          result: action.payload,
        },
      };
    case BULK_IMPORT_MEDIA_PLAN_FAILED:
      return {
        ...state,
        bulkimport: {
          ...state.bulkimport,
          isLoading: false,
          error: action.payload,
        },
      };
    case BULK_IMPORT_MEDIA_PLAN_RESET:
      return {
        ...state,
        bulkimport: initialState.bulkimport,
      };

    case BULK_EXPORT_MEDIA_PLAN_RESET:
      return {
        ...state,
        bulkexport: initialState.bulkexport,
      };
    case BULK_EXPORT_MEDIA_PLAN:
      return {
        ...state,
        bulkexport: {
          ...state.bulkexport,
          isLoading: true,
          error: null,
        },
      };
    case BULK_EXPORT_MEDIA_PLAN_SUCCESS:
      return {
        ...state,
        bulkexport: {
          ...state.bulkexport,
          isLoading: false,
        },
      };
    case BULK_EXPORT_MEDIA_PLAN_FAILED:
      return {
        ...state,
        bulkexport: {
          ...state.bulkexport,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default bulk;
