import {
  GET_REVENUE_SUMMARY,
  GET_REVENUE_SUMMARY_ERROR,
  GET_REVENUE_SUMMARY_SUCCESS,
  GENERATE_REVENUE,
  GENERATE_REVENUE_ERROR,
  GENERATE_REVENUE_RESET,
  GENERATE_REVENUE_SUCCESS,
} from './types';

const initialState = {
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
  generate: {
    isLoading: false,
    data: null,
    error: null,
    isGenerated: false,
  },
};

const summary = (state = initialState, action) => {
  switch (action.type) {
    case GET_REVENUE_SUMMARY:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
        },
      };
    case GET_REVENUE_SUMMARY_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_REVENUE_SUMMARY_ERROR:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    case GENERATE_REVENUE_RESET:
      return {
        ...state,
        generate: initialState.generate,
      };
    case GENERATE_REVENUE:
      return {
        ...state,
        generate: {
          ...state.generate,
          isLoading: true,
          data: null,
          error: null,
          isGenerated: false,
        },
      };
    case GENERATE_REVENUE_SUCCESS:
      return {
        ...state,
        generate: {
          ...state.generate,
          isLoading: false,
          data: action.payload,
          isGenerated: true,
        },
      };
    case GENERATE_REVENUE_ERROR:
      return {
        ...state,
        generate: {
          ...state.generate,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
export default summary;
