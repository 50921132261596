import { combineReducers } from 'redux';
import agency from './agency/reducers';
import auth from './auth/reducers';
import client from './client';
import common from './common/reducers';
import entity from './entity/reducers';
import subproducts from './subproducts/reducers';
import stories from './stories/reducers';
import products from './products/reducers';
import org from './org/reducers';
import users from './users';
import mediaPlan from './mediaPlan';
import invoice from './invoice';
import revenue from './revenue';
export default combineReducers({
  auth,
  agency,
  client,
  common,
  entity,
  subproducts,
  stories,
  products,
  org,
  users,
  mediaPlan,
  invoice,
  revenue,
});
