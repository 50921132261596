import MenuAlt2Icon from '@heroicons/react/outline/MenuAlt2Icon';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setModalStatus } from '../redux/common/actions';
import ProfileDropDown from './ProfileDropdown';
import SideBar from './Sidebar';
import Breadcrumb from './Breadcrumbs';

export default function MainLayout({ children }) {
  const dispatch = useDispatch();
  const profile = useSelector((state) => state.users.profile.get);

  return (
    <div className='h-screen bg-gray-50 overflow-auto'>
      <SideBar profile={profile.profile} />
      <div className='lg:pl-28 flex flex-col flex-1'>
        <div className='sticky top-0 z-40 flex-shrink-0 flex h-16 bg-white shadow'>
          <button
            type='button'
            className='px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-primary lg:hidden'
            onClick={() => dispatch(setModalStatus({ sidebarOpen: true }))}
          >
            <span className='sr-only'>Open sidebar</span>
            <MenuAlt2Icon className='h-6 w-6' aria-hidden='true' />
          </button>
          <div className='flex-1 px-4 flex justify-between'>
            <div className='flex-1 flex'></div>
            <div className='ml-4 flex items-center md:ml-6'>
              <ProfileDropDown />
            </div>
          </div>
        </div>
        <main>
          <div className='py-4'>
            <div className='mx-auto px-4 sm:px-6 md:px-8'>
              <Breadcrumb />
              {/* <h1 className='text-2xl font-semibold text-gray-900'>
                {startCase(window.location.pathname.split('/')[1])}
              </h1> */}
            </div>
            <div className='mx-auto px-4 sm:px-6 md:px-8'>
              <div className='py-1'>{children}</div>
            </div>
          </div>
        </main>
      </div>
    </div>
  );
}
