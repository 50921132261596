import { GET_PROFILE_SUCCESS, GET_PROFILE_FAILED, GET_PROFILE } from "./types";
import { roleAccessLevel } from "../../../utils/common";

const initialState = {
  get: {
    isLoading: false,
    error: null,
    profile: null,
  },
};

const profile = (state = initialState, action) => {
  switch (action.type) {
    case GET_PROFILE:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          profile: null,
          error: null,
        },
      };
    case GET_PROFILE_SUCCESS:
      action.payload["accessLevel"] =  roleAccessLevel[action.payload.role];
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          profile: action.payload,
        },
      };

    case GET_PROFILE_FAILED:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
export default profile;
