import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useSelector } from "react-redux";
import Logout from "../pages/Logout";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export default function ProfileDropDown() {
  const profile = useSelector((state) => state.users.profile.get);
  return (
    <Menu as="div" className="ml-3 relative">
      <div>
        <Menu.Button className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none">
          <span className="sr-only">Open user menu</span>
          <span className="inline-flex items-center justify-center h-8 w-8 rounded-full bg-gray-500">
            <span className="text-sm font-medium leading-none text-white">
              {profile.profile && profile.profile.first_name.length > 0
                ? profile.profile.first_name[0]
                : "Hi"}
            </span>
          </span>
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <Menu.Item key="logout">
            {({ active }) => (
              <Logout
                className={classNames(
                  active ? "bg-gray-100" : "",
                  "hover:bg-primary-verylight block px-4 py-2 text-sm text-primary"
                )}
              />
            )}
          </Menu.Item>
        </Menu.Items>
      </Transition>
    </Menu>
  );
}
