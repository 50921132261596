import React, { useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import MainLayout from '../layout/MainLayout';
import { URL_LOGIN } from './url';
import { myProfile } from '../redux/users/myprofile/actions';
import PageSpinner from '../component/PageSpinner';
import Unauthorized from '../pages/Unauthorized';
import { getCookie } from '../utils/cookies';
import { tokenKey } from '../utils/common';

/**
 *
 * @param path
 * @returns {JSX.Element}
 */
export const returnToRoute = (path) => {
  return <Redirect to={path} />;
};

/**
 *
 * @param Component
 * @param rest
 * @returns {JSX.Element}
 * @constructor
 */
const PrivateRoute = ({ component: Component, ...rest }) => {
  const dispatch = useDispatch();
  //const auth = useSelector((state) => state.auth);
  const profile = useSelector((state) => state.users.profile.get);
  //const { isAuthenticated } = auth;

  useEffect(() => {
    if (!profile.profile) {
      dispatch(myProfile());
    }
  }, [dispatch, profile.profile]);

  if (getCookie(tokenKey) === null) {
    return <Route {...rest} render={(props) => returnToRoute(URL_LOGIN)} />;
  } else {
    return profile.isLoading ? (
      <PageSpinner />
    ) : (profile.profile && profile.profile.accessLevel) <=
      rest.allowedAccessLevel ? (
      <Route
        {...rest}
        render={(props) => (
          <MainLayout>
            <Component {...props} />
          </MainLayout>
        )}
      />
    ) : (
      <Unauthorized />
    );
  }
};

export default PrivateRoute;
