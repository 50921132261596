import {
  GET_SUBPRODUCT,
  GET_SUBPRODUCT_FAILED,
  GET_SUBPRODUCT_SUCCESS,
  GET_PRODUCT_SUBPRODUCT,
  GET_PRODUCT_SUBPRODUCT_FAILED,
  GET_PRODUCT_SUBPRODUCT_SUCCESS,
} from './types';

const initialState = {
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
  mapping: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const subproducts = (state = initialState, action) => {
  switch (action.type) {
    case GET_SUBPRODUCT:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
        },
      };
    case GET_SUBPRODUCT_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_SUBPRODUCT_FAILED:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    case GET_PRODUCT_SUBPRODUCT:
      return {
        ...state,
        mapping: {
          ...state.mapping,
          isLoading: true,
          error: null,
        },
      };
    case GET_PRODUCT_SUBPRODUCT_SUCCESS:
      return {
        ...state,
        mapping: {
          ...state.mapping,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_PRODUCT_SUBPRODUCT_FAILED:
      return {
        ...state,
        mapping: {
          ...state.mapping,
          isLoading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
export default subproducts;
