import React from 'react';
import LogoImage from '../../assets/images/public/logo.png';

/**
 *
 * @param checked
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Logo({ ...props }) {
  return <img {...props} src={LogoImage} alt='Pyxis' />;
}
