export const GET_INVOICE = 'GET_INVOICE';
export const GET_INVOICE_SUCCESS = 'GET_INVOICE_SUCCESS';
export const GET_INVOICE_FAILED = 'GET_INVOICE_FAILED';

export const GET_INVOICE_DETAILS = 'GET_INVOICE_DETAILS';
export const GET_INVOICE_DETAILS_SUCCESS = 'GET_INVOICE_DETAILS_SUCCESS';
export const GET_INVOICE_DETAILS_FAILED = 'GET_INVOICE_DETAILS_ERROR';

export const UPDATE_INVOICE = 'UPDATE_INVOICE_DETAIL';
export const UPDATE_INVOICE_SUCCESS = 'UPDATE_INVOICE_DETAIL_SUCCESS';
export const UPDATE_INVOICE_FAILED = 'UPDATE_INVOICE_DETAIL_FAILED';
export const UPDATE_INVOICE_RESET = 'UPDATE_INVOICE_DETAIL_RESET';
export const UPDATE_INVOICE_LIST = 'UPDATE_INVOICE_LIST';

export const DELETE_INVOICE = 'DELETE_INVOICE';
export const DELETE_INVOICE_SUCCESS = 'DELETE_INVOICE_SUCCESS';
export const DELETE_INVOICE_FAILED = 'DELETE_INVOICE_DELETE';
export const DELETE_INVOICE_RESET = 'DELETE_INVOICE_RESET';

export const GENERATE_INVOICE = 'GENERATE_INVOICE';
export const GENERATE_INVOICE_SUCCESS = 'GENERATE_INVOICE_SUCCESS';
export const GENERATE_INVOICE_FAILED = 'GENERATE_INVOICE_FAILED';
export const GENERATE_INVOICE_RESET = 'GENERATE_INVOICE_RESET';

export const GET_INVOICE_PDF = 'GET_INVOICE_PDF';
export const GET_INVOICE_PDF_DONE = 'GET_INVOICE_PDF_DONE';
export const GET_INVOICE_PDF_ERROR = 'GET_INVOICE_PDF_ERROR';

export const DOWNLOAD_ALL_INVOICES = 'DOWNLOAD_ALL_INVOICES';
export const DOWNLOAD_ALL_INVOICES_DONE = 'DOWNLOAD_ALL_INVOICES_DONE';
export const DOWNLOAD_ALL_INVOICES_ERROR = 'DOWNLOAD_ALL_INVOICES_ERROR';

export const SET_INVOICE_STARTDATE_FILTER = 'SET_INVOICE_STARTDATE_FILTER';
