import { GET_PRODUCT, GET_PRODUCT_SUCCESS, GET_PRODUCT_FAILED } from './types';

const initialState = {
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const products = (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCT:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
        },
      };
    case GET_PRODUCT_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_PRODUCT_FAILED:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
export default products;
