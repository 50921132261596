import {
  CREATE_AGENCY,
  CREATE_AGENCY_SUCCESS,
  CREATE_AGENCY_FAILED,
  CREATE_AGENCY_RESET,
  GET_AGENCIES,
  GET_AGENCIES_FAILED,
  GET_AGENCIES_SUCCESS,
} from "./types";

const initialState = {
  create: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const agency = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_AGENCY:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          error: null,
          isCreated: false,
        },
      };

    case CREATE_AGENCY_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
        },
      };

    case CREATE_AGENCY_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };

    case CREATE_AGENCY_RESET:
      return { ...state, create: { ...initialState.create } };

    case GET_AGENCIES:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
          data: [],
        },
      };
    case GET_AGENCIES_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_AGENCIES_FAILED:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};

export default agency;
