import React from 'react';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Spinner({
  children,
  showasIcon = false,
  className = '',
  isSpinning = false,
  ...props
}) {
  return showasIcon ? (
    <svg
      className={`animate-spin -ml-1 mr-3 h-5 w-5 ${className}`}
      xmlns='http://www.w3.org/2000/svg'
      fill='none'
      viewBox='0 0 24 24'
      {...props}
    >
      <circle
        className='opacity-25'
        cx='12'
        cy='12'
        r='10'
        stroke='currentColor'
        strokeWidth='4'
      ></circle>
      <path
        className='opacity-75'
        fill='currentColor'
        d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
      ></path>
    </svg>
  ) : (
    <div className='relative'>
      {isSpinning && (
        <div className='absolute top-0 left-0 w-full h-full'>
          <svg
            className={`absolute top-2/4 left-2/4	animate-spin h-5 w-5 ${className}`}
            xmlns='http://www.w3.org/2000/svg'
            fill='none'
            viewBox='0 0 24 24'
            {...props}
          >
            <circle
              className='opacity-25'
              cx='12'
              cy='12'
              r='10'
              stroke='currentColor'
              strokeWidth='4'
            ></circle>
            <path
              className='opacity-75'
              fill='currentColor'
              d='M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z'
            ></path>
          </svg>
        </div>
      )}
      <div
        className={`${
          isSpinning ? 'select-none	pointer-events-none	opacity-50' : ''
        } `}
      >
        {children}
      </div>
    </div>
  );
}
