import React from 'react';
import Logo from '../Logo';
/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function PageSpinner({
  className = '',
  bgcolor = 'primary',
  ...props
}) {
  return (
    <div
      className={`fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden opacity-75 flex flex-col items-center justify-center ${
        bgcolor === 'primary' ? 'bg-primary' : 'bg-white'
      } ${className}`}
      {...props}
    >
      <Logo className='h-20 w-auto opacity-100  animate-bounce' />
    </div>
  );
}
