import { GET_ORGID, GET_ORGID_FAILED, GET_ORGID_SUCCESS } from './types';

const initialState = {
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const org = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGID:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
        },
      };
    case GET_ORGID_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload || [],
        },
      };
    case GET_ORGID_FAILED:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
export default org;
