/* This example requires Tailwind CSS v2.0+ */
import { Link, withRouter } from 'react-router-dom';
import { URL_HOME } from '../routes/url';
import { capitalize } from '../utils/textstyle';
import ChevronRightIcon from '@heroicons/react/solid/ChevronRightIcon';
import HomeIcon from '@heroicons/react/solid/HomeIcon';

export default withRouter(function Breadcrumb({ location, match, history }) {
  let pathSnippets = location.pathname
    .split('/')
    .filter((i) => (match.params.id ? match.params.id !== i : i));
  pathSnippets = pathSnippets.filter(function (el) {
    return el !== '';
  });
  const extraBreadcrumbItems = pathSnippets.map((_, index) => {
    const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
    const title = `${pathSnippets.slice(index, index + 1).join('/')}`
      .replace(/[\W_]+/g, ' ')
      .split(' ')
      .map((t) => t)
      .join(' ');

    return { url, title: capitalize(title), current: false };
  });
  return (
    <nav className='flex' aria-label='Breadcrumb'>
      <ol className='flex items-center space-x-4'>
        <li>
          <div>
            <Link to={URL_HOME} className='text-gray-400 hover:text-gray-500'>
              <HomeIcon className='flex-shrink-0 h-5 w-5' aria-hidden='true' />
              <span className='sr-only'>Home</span>
            </Link>
          </div>
        </li>
        {extraBreadcrumbItems.map((page) => (
          <li key={page.name}>
            <div className='flex items-center'>
              <ChevronRightIcon
                className='flex-shrink-0 h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
              <Link
                to={page.url}
                className={`ml-4 text-xl font-semibold text-primary hover:text-primary-dark`}
                aria-current={page.current ? 'page' : undefined}
              >
                {page.title}
              </Link>
            </div>
          </li>
        ))}
      </ol>
    </nav>
  );
});
