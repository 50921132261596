export const URL_LOGIN = '/login';
export const URL_CLIENTS = '/clients';
export const URL_CLIENTS_CREATE = `${URL_CLIENTS}/new`;
export const URL_MEDIAPLAN = '/mediaplan';
export const URL_MEDIAPLAN_CREATE = `${URL_MEDIAPLAN}/new`;
export const URL_INVOICE = '/invoice';
export const URL_REPORT = '/report';
export const URL_HOME = '/';
export const URL_ENTITY = '/entity';
export const URL_AGENCY = '/agency';
export const URL_REVENUE = '/revenue';
