import {
  GET_CURRENCIES,
  GET_CURRENCIES_FAILED,
  GET_CURRENCIES_SUCCESS,
  SET_MODAL_STATUS,
  GET_COUNTRY,
  GET_COUNTRY_FAILED,
  GET_COUNTRY_SUCCESS,
  GET_STATE,
  GET_STATE_FAILED,
  GET_STATE_SUCCESS,
} from './types';

const initialState = {
  modalStatus: {
    sidebarOpen: false,
    showEntity: false,
    showClient: false,
    showAgency: false,
    showMediaPlanBulkImport: false,
  },
  getCurrencies: {
    isLoading: false,
    error: null,
    currencies: [],
  },
  countries: {
    isLoading: false,
    error: null,
    data: [],
  },
  states: {
    isLoading: false,
    error: null,
    data: [],
  },
};

const auth = (state = initialState, action) => {
  switch (action.type) {
    case GET_COUNTRY:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: true,
        },
      };
    case GET_COUNTRY_SUCCESS:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_COUNTRY_FAILED:
      return {
        ...state,
        countries: {
          ...state.countries,
          isLoading: false,
          error: action.payload,
        },
      };
    case GET_STATE:
      return {
        ...state,
        states: {
          ...state.states,
          isLoading: true,
        },
      };
    case GET_STATE_SUCCESS:
      return {
        ...state,
        states: {
          ...state.states,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_STATE_FAILED:
      return {
        ...state,
        states: {
          ...state.states,
          isLoading: false,
          error: action.payload,
        },
      };
    case SET_MODAL_STATUS:
      return {
        ...state,
        modalStatus: {
          ...state.modalStatus,
          ...action.payload,
        },
      };

    case GET_CURRENCIES:
      return {
        ...state,
        getCurrencies: {
          ...state.getCurrencies,
          isLoading: true,
          error: null,
        },
      };
    case GET_CURRENCIES_SUCCESS:
      return {
        ...state,
        getCurrencies: {
          ...state.getCurrencies,
          isLoading: false,
          currencies: action.payload.currencies,
        },
      };
    case GET_CURRENCIES_FAILED:
      return {
        ...state,
        getCurrencies: {
          ...state.getCurrencies,
          isLoading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};
export default auth;
