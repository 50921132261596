export const apiPaths = {
  auth: {
    login: '/login/',
  },
  common: {
    currency: '/currency/',
    country: '/country/',
    state: '/state/',
  },
  users: {
    myprofile: '/users/profile/',
  },
  agency: {
    agency: '/agency/',
  },
  client: {
    agreement: '/agreement/',
    clients: '/clients/',
  },
  mediaplan: {
    mediaplan: '/mediaplan/',
    recalculate: '/recalculate/',
  },
  products: '/products/',
  subproducts: {
    subproducts: '/subproducts/',
    mapping: '/product-subproduct-mapping/',
  },
  enitity: '/entity/',
  stories: '/fetch_stories/',
  org: '/fetch_orgs/',
  invoice: {
    invoice: '/invoice/',
    getpdf: '/invoice_pdf',
    email: '/mail_trigger/',
  },
  revenue: {
    summary: '/revenue/',
  },
};
