import {
  CREATE_CLIENT,
  CREATE_CLIENT_SUCCESS,
  CREATE_CLIENT_FAILED,
  CREATE_CLIENT_RESET,
  GET_CLIENT,
  GET_CLIENT_SUCCESS,
  GET_CLIENT_FAILED,
} from "./types";

const initialState = {
  create: {
    isLoading: false,
    isCreated: false,
    error: null,
    data: null,
  },
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
};
const manage = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CLIENT_RESET:
      return {
        ...state,
        create: initialState.create,
      };
    case CREATE_CLIENT:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          isCreated: false,
          data: null,
          error: null,
        },
      };
    case CREATE_CLIENT_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
          data: action.payload,
        },
      };
    case CREATE_CLIENT_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };
    case GET_CLIENT:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
        },
      };
    case GET_CLIENT_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_CLIENT_FAILED: {
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    }
    default:
      return state;
  }
};
export default manage;
