import React from 'react';
import Spinner from '../Spinner';

/**
 *
 * @param props
 * @returns {*}
 * @constructor
 */
export default function Button({
  children,
  htmlType = 'button',
  type = 'primary',
  size = 'default', //options : small py-1 px-1 , default py-2 px-4
  className = '',
  loading = false,
  disabled = false,
  ...props
}) {
  const cssClass = () => {
    let css = `inline-flex justify-center border border-transparent rounded-md shadow-sm text-sm font-medium focus:outline-none focus:ring-2 focus:ring-offset-2 ${
      size === 'small' ? 'px-1 py-1' : 'py-2 px-4'
    }`;
    if (type === 'primary') {
      css = `${css} text-white bg-secondary hover:bg-secondary-dark focus:ring-secondary`;
    } else if (type === 'secondary') {
      css = `${css} text-primary bg-primary-light hover:bg-primary-verylight focus:ring-primary-verylight`;
    } else if (type === 'link') {
      css = `${css} text-primary background-transparent hover:text-primary-mild outline-none focus:outline-none`;
    }
    return `${
      disabled && 'cursor-not-allowed disabled:opacity-50'
    } ${css} ${className}`;
  };
  return (
    <button
      disabled={disabled}
      type={htmlType}
      className={cssClass()}
      {...props}
    >
      {loading && <Spinner showasIcon={true} className='text-white' />}
      {children !== undefined && children}
    </button>
  );
}
