import LogoutIcon from "@heroicons/react/outline/LogoutIcon";
import React from "react";
import { useHistory } from "react-router-dom";
import Button from "../../component/Button";
import { URL_LOGIN } from "../../routes/url";
import { tokenKey } from "../../utils/common";
import { eraseCookie } from "../../utils/cookies";

const Logout = ({ className = "" }) => {
  const history = useHistory();
  const logoutUser = () => {
    eraseCookie(tokenKey);
    history.push(URL_LOGIN);
  };

  return (
    <Button onClick={logoutUser} className={`w-full ${className}`} type="link">
      <LogoutIcon className="-ml-1 mr-3 h-5 w-5" aria-hidden="true" /> Logout
    </Button>
  );
};

export default Logout;
