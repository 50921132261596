import apiservice from "../../../api/axios";
import { apiPaths } from "../../../api/paths";
import {
  GET_PROFILE,
  GET_PROFILE_FAILED,
  GET_PROFILE_SUCCESS,
} from "./types";
import { formatAxiosErrorMessage } from "../../../utils/common";

export const myProfile = () => (dispatch) => {
  dispatch({ type: GET_PROFILE });
  apiservice
    .get(apiPaths.users.myprofile)
    .then((res) => {
      dispatch({
        type: GET_PROFILE_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_PROFILE_FAILED,
        payload: formatAxiosErrorMessage(err),
      });
    });
};
