import axios from 'axios';
import { getCookie } from '../utils/cookies';
import { tokenKey } from '../utils/common';

//export const base_url = 'http://127.0.0.1:8000/api/v1'; //'http://3.86.109.87:8080/api/v1'; //process.env.API_URL;
export const base_url = process.env.REACT_APP_API_URL;

const apiservice = () => {
  const defaultOptions = {
    baseURL: base_url,
    headers: {
      'Content-Type': 'application/json',
    },
  };

  // Create instance
  let instance = axios.create(defaultOptions);

  // Set the AUTH token for any request
  instance.interceptors.request.use(function (config) {
    config.headers.Authorization = `Bearer ${getCookie(tokenKey)}`;
    return config;
  });

  return instance;
};

// export default fetchClient();
export default apiservice();
