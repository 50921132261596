import { createStore, applyMiddleware, compose } from 'redux';
import rootReducer from '../redux';
import thunkMiddleware from 'redux-thunk';

const composeEnhancers =
  process.env.REACT_APP_ENVIRONMENT !== 'prod'
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    : null || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);

export default store;
