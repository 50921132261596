import { GET_STORIES_FAILED, GET_STORIES, GET_STORIES_SUCCESS } from "./types";

const initialState = {
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
};

const stories = (state = initialState, action) => {
  switch (action.type) {
    case GET_STORIES:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
        },
      };
    case GET_STORIES_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload.data.stories || [],
        },
      };
    case GET_STORIES_FAILED:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
export default stories;
