import React, { lazy, Suspense } from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import PageSpinner from '../component/PageSpinner';
import * as URL from './url';
/** Lazy load components */
const Login = lazy(() => import('../pages/Login'));
const EntityList = lazy(() => import('../pages/Entity/EntityList'));
const Agreement = lazy(() =>
  import('../pages/Clients/Agreement/AgreementDashboard')
);
const CreateAgency = lazy(() => import('../pages/Agency/CreateAgency'));
const CreateAgreement = lazy(() =>
  import('../pages/Clients/Agreement/CreateAgreement')
);
const UpdateAgreement = lazy(() =>
  import('../pages/Clients/Agreement/UpdateAgreement')
);
const MediaPlan = lazy(() => import('../pages/MediaPlan/MediaPlanDashboard'));
const CreateMediaPlan = lazy(() =>
  import('../pages/MediaPlan/CreateMediaPlan')
);
const UpdateMediaPlan = lazy(() =>
  import('../pages/MediaPlan/UpdateMediaPlan')
);
const Invoice = lazy(() => import('../pages/Invoice/InvoiceDashborad'));
// const Revenue = lazy(() => import('../pages/Revenue/RevenueDashboard'));

const routes = {
  private: [
    {
      path: URL.URL_HOME,
      component: Agreement,
      allowedAccessLevel: 2,
    },
    {
      path: URL.URL_CLIENTS,
      component: Agreement,
      allowedAccessLevel: 2,
    },
    {
      path: URL.URL_CLIENTS_CREATE,
      component: CreateAgreement,
      allowedAccessLevel: 2,
    },
    {
      path: `${URL.URL_CLIENTS}/:id`,
      component: UpdateAgreement,
      allowedAccessLevel: 2,
    },
    {
      path: URL.URL_MEDIAPLAN_CREATE,
      component: CreateMediaPlan,
      allowedAccessLevel: 1,
    },
    {
      path: URL.URL_MEDIAPLAN,
      component: MediaPlan,
      allowedAccessLevel: 1,
    },
    {
      path: `${URL.URL_MEDIAPLAN}/:id`,
      component: UpdateMediaPlan,
      allowedAccessLevel: 1,
    },
    {
      path: URL.URL_AGENCY,
      component: CreateAgency,
      allowedAccessLevel: 2,
    },
    {
      path: URL.URL_ENTITY,
      component: EntityList,
      allowedAccessLevel: 1,
    },
    {
      path: URL.URL_INVOICE,
      component: Invoice,
      allowedAccessLevel: 1,
    },
  ],
  public: [
    {
      path: URL.URL_LOGIN,
      component: Login,
    },
  ],
};
export default function URLRoutes() {
  return (
    <Suspense fallback={<PageSpinner />}>
      <BrowserRouter>
        <Switch>
          {routes.private.map((route, key) => {
            const { component, path, allowedAccessLevel } = route;
            return (
              <PrivateRoute
                exact
                path={path}
                key={key}
                component={component}
                allowedAccessLevel={allowedAccessLevel}
              />
            );
          })}
          {routes.public.map((route, key) => {
            const { component, path } = route;
            return (
              <PublicRoute exact path={path} key={key} component={component} />
            );
          })}
        </Switch>
      </BrowserRouter>
    </Suspense>
  );
}
