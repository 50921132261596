import {
  CREATE_MEDIA_PLAN,
  CREATE_MEDIA_PLAN_FAILED,
  CREATE_MEDIA_PLAN_SUCCESS,
  CREATE_MEDIA_PLAN_RESET,
  DELETE_MEDIA_PLAN,
  DELETE_MEDIA_PLAN_FAILED,
  DELETE_MEDIA_PLAN_RESET,
  DELETE_MEDIA_PLAN_SUCCESS,
  GET_MEDIA_PLAN,
  GET_MEDIA_PLAN_DETAILS,
  GET_MEDIA_PLAN_DETAILS_FAILED,
  GET_MEDIA_PLAN_DETAILS_SUCCESS,
  GET_MEDIA_PLAN_FAILED,
  GET_MEDIA_PLAN_SUCCESS,
  UPDATE_MEDIA_PLAN,
  UPDATE_MEDIA_PLAN_FAILED,
  UPDATE_MEDIA_PLAN_RESET,
  UPDATE_MEDIA_PLAN_SUCCESS,
  UPDATE_MEDIA_PLAN_LIST,
  SET_MEDIA_PLAN_STARTDATE_FILTER,
} from './types';
import {
  currentMonthFirstDay,
  currentMonthLastDay,
} from '../../../utils/common';
const initialState = {
  startDateFilter: {
    start: currentMonthFirstDay,
    end: currentMonthLastDay,
  },
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
  create: {
    isLoading: false,
    isCreated: false,
    data: [],
    error: null,
  },
  retrieve: {
    isLoading: false,
    data: null,
    error: null,
  },
  save: {
    isLoading: false,
    isUpdated: false,
    error: null,
    data: null,
  },
  remove: {
    isLoading: false,
    isDeleted: false,
    error: null,
  },
};
let media = [];
const manage = (state = initialState, action) => {
  switch (action.type) {
    case SET_MEDIA_PLAN_STARTDATE_FILTER:
      return {
        ...state,
        startDateFilter: action.payload,
      };
    case GET_MEDIA_PLAN:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
        },
      };
    case GET_MEDIA_PLAN_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_MEDIA_PLAN_FAILED:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };

    case CREATE_MEDIA_PLAN_RESET:
      return {
        ...state,
        create: initialState.create,
      };
    case CREATE_MEDIA_PLAN:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          error: null,
          isCreated: false,
        },
      };
    case CREATE_MEDIA_PLAN_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
        },
      };
    case CREATE_MEDIA_PLAN_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };

    case GET_MEDIA_PLAN_DETAILS:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: true,
          data: null,
          error: null,
        },
      };
    case GET_MEDIA_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_MEDIA_PLAN_DETAILS_FAILED:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: false,
          error: action.payload,
        },
      };

    case DELETE_MEDIA_PLAN_RESET:
      return {
        ...state,
        remove: initialState.remove,
      };
    case DELETE_MEDIA_PLAN:
      return {
        ...state,
        remove: {
          ...state.remove,
          isLoading: true,
          isDeleted: false,
          error: null,
        },
      };
    case DELETE_MEDIA_PLAN_SUCCESS:
      media = state.get.data.filter(
        (mediaplan) => mediaplan.id !== action.payload
      );
      return {
        ...state,
        get: {
          ...state.get,
          data: media,
        },
        remove: {
          ...state.remove,
          isLoading: false,
          isDeleted: true,
        },
      };
    case DELETE_MEDIA_PLAN_FAILED:
      return {
        ...state,
        remove: {
          ...state.remove,
          isLoading: false,
          error: action.payload,
        },
      };

    case UPDATE_MEDIA_PLAN_LIST:
      media = state.get.data.map((m) => {
        let match = action.payload.filter((newM) => m.id === newM.id);
        if (match.length > 0) {
          return { ...match[0] };
        }
        return { ...m };
      });
      return {
        ...state,
        get: {
          ...state.get,
          data: media,
        },
      };
    case UPDATE_MEDIA_PLAN_RESET:
      return {
        ...state,
        save: initialState.save,
      };
    case UPDATE_MEDIA_PLAN:
      return {
        ...state,
        save: {
          ...state.save,
          isLoading: true,
          isUpdated: false,
          data: null,
          error: null,
        },
      };
    case UPDATE_MEDIA_PLAN_SUCCESS:
      return {
        ...state,
        save: {
          ...state.save,
          isLoading: false,
          isUpdated: true,
          data: action.payload,
        },
      };
    case UPDATE_MEDIA_PLAN_FAILED:
      return {
        ...state,
        save: {
          ...state.save,
          isLoading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default manage;
