import {
  CREATE_AGREEMENT,
  CREATE_AGREEMENT_FAILED,
  CREATE_AGREEMENT_RESET,
  CREATE_AGREEMENT_SUCCESS,
  DELETE_AGREEMENT,
  DELETE_AGREEMENT_FAILED,
  DELETE_AGREEMENT_RESET,
  DELETE_AGREEMENT_SUCCESS,
  GET_AGREEMENTS,
  GET_AGREEMENTS_FAILED,
  GET_AGREEMENTS_SUCCESS,
  GET_AGREEMENT_DETAILS,
  GET_AGREEMENT_DETAILS_FAILED,
  GET_AGREEMENT_DETAILS_SUCCESS,
  UPDATE_AGREEMENT,
  UPDATE_AGREEMENT_FAILED,
  UPDATE_AGREEMENT_RESET,
  UPDATE_AGREEMENT_SUCCESS,
  BULK_EXPORT_AGREEMENT,
  BULK_EXPORT_AGREEMENT_FAILED,
  BULK_EXPORT_AGREEMENT_RESET,
  BULK_EXPORT_AGREEMENT_SUCCESS,
} from './types';

const initialState = {
  get: {
    isLoading: false,
    data: [],
    error: null,
  },
  retrieve: {
    isLoading: false,
    data: null,
    error: null,
  },
  create: {
    isLoading: false,
    isCreated: false,
    error: null,
    data: null,
  },
  save: {
    isLoading: false,
    isUpdated: false,
    error: null,
    data: null,
  },
  remove: {
    isLoading: false,
    isDeleted: false,
    error: null,
  },
  bulkexport: {
    isLoading: false,
    error: null,
  },
};

const agreement = (state = initialState, action) => {
  switch (action.type) {
    case GET_AGREEMENTS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: true,
          error: null,
        },
      };
    case GET_AGREEMENTS_SUCCESS:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_AGREEMENTS_FAILED:
      return {
        ...state,
        get: {
          ...state.get,
          isLoading: false,
          error: action.payload,
        },
      };
    case CREATE_AGREEMENT_RESET:
      return {
        ...state,
        create: initialState.create,
      };
    case CREATE_AGREEMENT:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: true,
          isCreated: false,
          data: null,
          error: null,
        },
      };
    case CREATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          isCreated: true,
          data: action.payload,
        },
      };
    case CREATE_AGREEMENT_FAILED:
      return {
        ...state,
        create: {
          ...state.create,
          isLoading: false,
          error: action.payload,
        },
      };

    case UPDATE_AGREEMENT_RESET:
      return {
        ...state,
        save: initialState.save,
      };
    case UPDATE_AGREEMENT:
      return {
        ...state,
        save: {
          ...state.save,
          isLoading: true,
          isUpdated: false,
          data: null,
          error: null,
        },
      };
    case UPDATE_AGREEMENT_SUCCESS:
      return {
        ...state,
        save: {
          ...state.save,
          isLoading: false,
          isUpdated: true,
          data: action.payload,
        },
      };
    case UPDATE_AGREEMENT_FAILED:
      return {
        ...state,
        save: {
          ...state.save,
          isLoading: false,
          error: action.payload,
        },
      };

    case DELETE_AGREEMENT_RESET:
      return {
        ...state,
        remove: initialState.remove,
      };
    case DELETE_AGREEMENT:
      return {
        ...state,
        remove: {
          ...state.remove,
          isLoading: true,
          isDeleted: false,
          error: null,
        },
      };
    case DELETE_AGREEMENT_SUCCESS:
      let agreements = state.get.data.filter(
        (agreement) => agreement.id !== action.payload
      );
      return {
        ...state,
        get: {
          ...state.get,
          data: agreements,
        },
        remove: {
          ...state.remove,
          isLoading: false,
          isDeleted: true,
        },
      };
    case DELETE_AGREEMENT_FAILED:
      return {
        ...state,
        remove: {
          ...state.remove,
          isLoading: false,
          error: action.payload,
        },
      };

    case GET_AGREEMENT_DETAILS:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: true,
          data: null,
          error: null,
        },
      };
    case GET_AGREEMENT_DETAILS_SUCCESS:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: false,
          data: action.payload,
        },
      };
    case GET_AGREEMENT_DETAILS_FAILED:
      return {
        ...state,
        retrieve: {
          ...state.retrieve,
          isLoading: false,
          error: action.payload,
        },
      };

    case BULK_EXPORT_AGREEMENT_RESET:
      return {
        ...state,
        bulkexport: initialState.bulkexport,
      };
    case BULK_EXPORT_AGREEMENT:
      return {
        ...state,
        bulkexport: {
          ...state.bulkexport,
          isLoading: true,
          error: null,
        },
      };
    case BULK_EXPORT_AGREEMENT_SUCCESS:
      return {
        ...state,
        bulkexport: {
          ...state.bulkexport,
          isLoading: false,
        },
      };
    case BULK_EXPORT_AGREEMENT_FAILED:
      return {
        ...state,
        bulkexport: {
          ...state.bulkexport,
          isLoading: false,
          error: action.payload,
        },
      };
    default:
      return state;
  }
};
export default agreement;
