import {
  RECALCULATE_MEDIA_PLAN,
  RECALCULATE_MEDIA_PLAN_FAILED,
  RECALCULATE_MEDIA_PLAN_SUCCESS,
  RECALCULATE_MEDIA_PLAN_RESET,
} from "./types";

const initialState = {
  isLoading: false,
  isUpdated: false,
  error: null,
  data: null,
};

const recalculate = (state = initialState, action) => {
  switch (action.type) {
    case RECALCULATE_MEDIA_PLAN_RESET:
      return initialState;

    case RECALCULATE_MEDIA_PLAN:
      return {
        ...state,
        isLoading: true,
        isUpdated: false,
        error: null,
        data: null,
      };

    case RECALCULATE_MEDIA_PLAN_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isUpdated: true,
        data: action.payload,
      };
    case RECALCULATE_MEDIA_PLAN_FAILED:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default recalculate;
