import apiservice from '../../api/axios';
import { apiPaths } from '../../api/paths';
import { formatAxiosErrorMessage, qargsToQstring } from '../../utils/common';
import {
  GET_CURRENCIES,
  GET_CURRENCIES_FAILED,
  GET_CURRENCIES_SUCCESS,
  SET_MODAL_STATUS,
  GET_COUNTRY_FAILED,
  GET_COUNTRY_SUCCESS,
  GET_STATE_SUCCESS,
  GET_STATE_FAILED,
  GET_STATE,
  GET_COUNTRY,
} from './types';

export const setModalStatus = (value) => {
  return {
    type: SET_MODAL_STATUS,
    payload: value,
  };
};

export const reset = (RESET) => {
  return {
    type: RESET,
  };
};

export const getCurrencies = () => (dispatch) => {
  dispatch({ type: GET_CURRENCIES });
  apiservice
    .get(apiPaths.common.currency)
    .then((res) => {
      dispatch({
        type: GET_CURRENCIES_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_CURRENCIES_FAILED,
        payload: formatAxiosErrorMessage(err),
      });
    });
};

export const getCountries = () => (dispatch) => {
  dispatch({ type: GET_COUNTRY });
  apiservice
    .get(apiPaths.common.country)
    .then((res) => {
      dispatch({
        type: GET_COUNTRY_SUCCESS,
        payload: res.data,
      });
    })
    .catch((err) => {
      dispatch({
        type: GET_COUNTRY_FAILED,
        payload: formatAxiosErrorMessage(err),
      });
    });
};

export const getStates =
  (args = {}) =>
  (dispatch) => {
    dispatch({ type: GET_STATE });
    apiservice
      .get(`${apiPaths.common.state}${qargsToQstring(args)}`)
      .then((res) => {
        dispatch({
          type: GET_STATE_SUCCESS,
          payload: res.data,
        });
      })
      .catch((err) => {
        dispatch({
          type: GET_STATE_FAILED,
          payload: formatAxiosErrorMessage(err),
        });
      });
  };
