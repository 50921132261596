import {
  TRIGGER_EMAIL_DRAFT,
  TRIGGER_EMAIL_DRAFT_FAILED,
  TRIGGER_EMAIL_DRAFT_SUCCESS,
  TRIGGER_EMAIL_DRAFT_RESET,
} from './types';

const initialState = {
  draft: {
    isLoading: false,
    isSent: false,
    data: null,
    error: null,
  },
};

const email = (state = initialState, action) => {
  switch (action.type) {
    case TRIGGER_EMAIL_DRAFT_RESET:
      return {
        ...state,
        draft: initialState.draft,
      };
    case TRIGGER_EMAIL_DRAFT:
      return {
        ...state,
        draft: {
          ...state.draft,
          isLoading: true,
          isSent: false,
          error: null,
        },
      };
    case TRIGGER_EMAIL_DRAFT_SUCCESS:
      return {
        ...state,
        draft: {
          ...state.draft,
          isLoading: false,
          isSent: true,
          data: action.payload,
        },
      };
    case TRIGGER_EMAIL_DRAFT_FAILED:
      return {
        ...state,
        draft: {
          ...state.draft,
          isLoading: false,
          error: action.payload,
        },
      };

    default:
      return state;
  }
};

export default email;
